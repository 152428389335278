// General
import { FormikProps } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// Ui-kit
import Button from 'ui-kit/button/button';
import { Checkbox } from 'ui-kit/checkbox';
import Link from 'ui-kit/link/link';
import PageSection from 'ui-kit/page-section/page-section';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import Spinner from 'ui-kit/spinner/spinner';

// Interfaces, types & Utils
import { MembershipAvailablePlanCardProps } from 'display-components/membership/available-plan/card';
import MembershipAvailablePlanCardList from 'display-components/membership/available-plan/card-list';
import MembershipCartTotals from 'display-components/membership/membership-cart-totals';
import { MembershipSettingsErrorModal } from 'display-components/membership/modals';
import PageSectionTitle from 'display-components/page-section-title';
import TabList from 'display-components/tab-list/tab-list.component';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
// Components
import Footer from 'components/footer/footer.component';
import InternalHeader from 'components/internal-header/internal-header.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipPaymentFormPromoCode from 'components/membership-payment-form-promo-code/membership-payment-form-promo-code.component';
import { BadgePath, NavPath } from 'components/navigation/navigation.props';
import PaymentMethods from 'components/payment-methods/payment-methods.component';

// State
import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountProfileApiStatusSelector,
    accountProfileMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { membershipRegistrationGetPlanDetailsRoutine } from 'state/membership-registration/membership-registration.routines';
import { membershipRegistrationSelectPlanListSelector } from 'state/membership-registration/membership-registration.selectors';
import { membershipActions } from 'state/membership/membership.reducers';
import {
    membershipGetUpgradablePlansRoutine,
    membershipUpgradePlanRoutine
} from 'state/membership/membership.routines';
import {
    membershipDetailSelector,
    membershipIsCarePlanSelector,
    membershipIsLoadingDetailsSelector,
    membershipIsLoadingUpgradePlanSelector,
    membershipIsOnDemandSelector,
    membershipIsPlanUpgradableSelector,
    membershipIsValidPromoCodeSelector,
    membershipSelectedPlanToUpgradeSelector,
    membershipUpgradePlanPaymentModelSelector,
    membershipUpgradePlanPricingDetailsSelector
} from 'state/membership/membership.selector';

import { formatPrice } from 'schema/price.schema';

import { CreditCardPayload } from 'types/credit-card';
import { MembershipPricingDetailsResponse, MembershipUpgradePlanPayload } from 'types/membership';

import { getPhoneNumber } from 'util/globalVariables';
// Utils
import { DEFAULT_MEMBERSHIP_JOIN_FEE, generateAvailablePlans, MEMBERSHIP_RESPONSE_ERRORS } from 'util/membership';

import { useApiStatus } from 'hooks/useApiStatus';

// Styles
import './membership-upgrade-your-plan-page.style.scss';

// Main component
const MembershipUpgradePlanPage = ({ data }: { data: GatsbyTypes.MembershipUpgradePlanPageDataQuery }) => {
    // General
    const { whiteFeathersBackground } = data;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [availablePlans, setAvailablePlans] = useState<MembershipAvailablePlanCardProps[]>([]);
    const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>(undefined);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<CreditCardPayload | undefined>(undefined);
    const [showTermsError, setShowTermsError] = useState<boolean>(false);
    const [showAuthorizeError, setShowAuthorizeError] = useState<boolean>(false);
    const [isTerms, setIsTerms] = useState<boolean>(false);
    const [isAuthorize, setIsAuthorize] = useState<boolean>(false);
    const [promoCode, setPromoCode] = useState<string>('');

    // Refs
    const promoCodeRef = useRef<FormikProps<{ promoCode: string }>>(null);

    // Selectors
    // Account
    const profileObject = useSelector(accountProfileSelector);
    const { membershipID } = useSelector(accountProfileMembershipSelector);
    // Membership
    const membershipDetails = useSelector(membershipDetailSelector);
    const isPlanUpgradable = useSelector(membershipIsPlanUpgradableSelector);
    const { isLoading: isProfileLoading } = useApiStatus(accountProfileApiStatusSelector);
    const isLoadingDetails = useSelector(membershipIsLoadingDetailsSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const isCarePlan = useSelector(membershipIsCarePlanSelector);
    const paymentModel = useSelector(membershipUpgradePlanPaymentModelSelector);
    const pricingDetails = useSelector(membershipUpgradePlanPricingDetailsSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const selectedPlanToUpgrade = useSelector(membershipSelectedPlanToUpgradeSelector);
    const isLoadingUpgradePlan = useSelector(membershipIsLoadingUpgradePlanSelector);
    const isValidPromoCode = useSelector(membershipIsValidPromoCodeSelector);
    const defaultTabOption = 'Monthly';

    // Membership Registration
    const planDetails = useSelector(membershipRegistrationSelectPlanListSelector);

    // Pages variables
    const showLoading = allPaymentData === undefined;

    const activeCreditCards = useMemo(() => {
        return allPaymentData?.filter((card) => card.cardActive);
    }, [allPaymentData]);

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(activeCreditCards) &&
            activeCreditCards.length > 0 &&
            activeCreditCards?.find((card) => card.defaultMembershipPayment)
            ? activeCreditCards?.find((card) => card.defaultMembershipPayment)
            : activeCreditCards?.find((card) => card.defaultCard);
    }, [activeCreditCards]);

    /** Find the selected plan in the state */
    const selectedPlan = pricingDetails.find((plan) => plan.PlanId === selectedPlanToUpgrade.membershipPlanId);
    /** Validate if the user has plan Adjustment to show the blurb */
    const hasAdjustment =
        isCarePlan && paymentModel === 'Annually' && selectedPlan?.ChargeAmount !== selectedPlan?.RecurringBilling;
    /** As the OnDemandVisit user has a different pricing logic as they can select between monthly or anual payments whe separate the total value logic */
    const onDemandUserTotalPrice =
        isOnDemandPlan && paymentModel === 'Annually'
            ? selectedPlan?.AnnualPrice
                ? selectedPlan?.AnnualPrice
                : 0
            : selectedPlan?.MonthlyPrice
            ? selectedPlan?.MonthlyPrice
            : 0;

    const careUserTotalPrice = isCarePlan && selectedPlan?.ChargeAmount ? selectedPlan?.ChargeAmount : 0;

    const onDemandUserAppliedDiscount =
        paymentModel === 'Annually'
            ? selectedPlan?.AnnualDiscount
            : paymentModel === 'Monthly'
            ? selectedPlan?.MonthlyDiscount
            : 0;

    const careUserAppliedDiscount =
        isCarePlan && selectedPlan?.DiscountChargeAmount ? selectedPlan?.DiscountChargeAmount : 0;

    const InitialChargeAmount = isCarePlan
        ? selectedPlan?.InitialChargeAmount
        : paymentModel === 'Annually'
        ? selectedPlan?.AnnualInitialChargeAmount
        : paymentModel === 'Monthly'
        ? selectedPlan?.MonthlyInitialChargeAmount
        : 0;

    // Functions

    /**
     * Function to handle the plan selection.
     *
     * @param selectedPlan MembershipUpgradePlanPayload
     */
    const handlePlanSelection = useCallback(
        (selectedPlan: Partial<MembershipUpgradePlanPayload>) => {
            if (!selectedPlan) return;

            if (!selectedPlanId) {
                setSelectedPlanId(selectedPlan.membershipPlanId);
                dispatch(
                    membershipActions.setSelectedPlan({
                        ...selectedPlan,
                        chargeAmount: selectedPlan.chargeAmount,
                        newValuePlan: selectedPlan.newValuePlan
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSelectedPlanId, dispatch]
    );

    /**
     * Function to handle the paymentMethod selection.
     *
     * @param CreditCardPayload selectedPaymentMethod
     */
    const handlePaymentMethodSelection = (selectedPaymentMethod: CreditCardPayload) => {
        setSelectedPaymentMethod(selectedPaymentMethod);
        dispatch(
            membershipActions.setSelectedPlan({
                ...selectedPlan,
                cardSeqNum: selectedPaymentMethod.cardSeqNum
            })
        );
    };

    /**
     * Function to handle promoCode validation through the API.
     *
     * @param {string} promoCode - The code what user insered.
     * @returns A Validated response from the Plans API to know if the promoCode is valid.
     */
    const submitPromCode = (values: Partial<{ promoCode: string }>) => {
        setPromoCode(values.promoCode ?? '');
        dispatch(
            membershipGetUpgradablePlansRoutine.trigger({
                data: { epostPatientNum: profileObject?.epostPatientNum, promoCode: values.promoCode },
                onSuccess: (response: MembershipPricingDetailsResponse) => {
                    const recurrence = paymentModel === 'Annually' ? 12 : 1;
                    const findUpdatedPrice = response.PricingDetails.find(
                        (f) => f.PlanId === selectedPlanToUpgrade.membershipPlanId && f.TypeOfRecurrence === recurrence
                    );
                    dispatch(
                        membershipActions.setSelectedPlan({
                            ...selectedPlanToUpgrade,
                            chargeAmount: findUpdatedPrice?.ChargeAmount,
                            newValuePlan: findUpdatedPrice?.RecurringBilling,
                            promoCode: values.promoCode
                        })
                    );
                },
                onFailure: () => {
                    setPromoCode('');
                    dispatch(
                        membershipGetUpgradablePlansRoutine.trigger({
                            data: { epostPatientNum: profileObject?.epostPatientNum }
                        })
                    );
                }
            })
        );
    };

    /**
     * Function that handles the remove discount logic.
     */
    const handleRemoveDiscount = () => {
        setPromoCode('');
        promoCodeRef.current?.resetForm();
        dispatch(
            membershipGetUpgradablePlansRoutine.trigger({
                data: { epostPatientNum: profileObject?.epostPatientNum },
                onSuccess: (response: MembershipPricingDetailsResponse) => {
                    const recurrence = paymentModel === 'Annually' ? 12 : 1;
                    const findUpdatedPrice = response.PricingDetails.find(
                        (f) => f.PlanId === selectedPlanToUpgrade.membershipPlanId && f.TypeOfRecurrence === recurrence
                    );
                    dispatch(
                        membershipActions.setSelectedPlan({
                            ...selectedPlanToUpgrade,
                            chargeAmount: findUpdatedPrice?.ChargeAmount,
                            newValuePlan: findUpdatedPrice?.RecurringBilling,
                            promoCode: undefined
                        })
                    );
                }
            })
        );
    };

    /**
     * Function that handles the open state of the Terms modal.
     */
    const handleTermsModal = () => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        title={t('membership.selectPlan.membershipTerms')}
                        body={
                            <div
                                className="text-left terms-modal-body"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(t('membership.selectPlan.membershipTermsBody'))
                                }}
                            ></div>
                        }
                    />
                ),
                className: 'birdi-terms-modal',
                ctas: [],
                showClose: true,
                size: 'lg'
            })
        );
    };

    /**
     * Function that handles the open state of the Terms modal.
     */
    const handleCheckTerms = (isChecked: boolean) => {
        setIsTerms(isChecked);

        if (isChecked) {
            setShowTermsError(false);
        }
    };

    /**
     * Function that handles the open state of the Terms modal.
     */
    const handleCheckAuthorize = (isChecked: boolean) => {
        setIsAuthorize(isChecked);

        if (isChecked) {
            setShowAuthorizeError(false);
        }
    };

    /**
     * Function that verify the authorize checkbox
     */
    const isAuthorizeValid = (): boolean => {
        return isOnDemandPlan && isAuthorize;
    };

    /**
     * Function that verify the terms checkbox
     */
    const isTermsValid = (): boolean => {
        return isOnDemandPlan && isTerms;
    };

    /**
     * Function to change price tabs and reset plan selection
     */
    const handleTabChange = (tab: string): void => {
        dispatch(membershipActions.setPayMentModel(tab));
        setSelectedPlanId(undefined);
        dispatch(
            membershipGetUpgradablePlansRoutine.trigger({
                data: { epostPatientNum: profileObject?.epostPatientNum }
            })
        );
    };

    /**
     * Function to handle upgrade plan
     *
     * @returns a void function
     */
    const handleUpgradePlan = () => {
        if (!selectedPlanToUpgrade) return;
        const payload = {
            ...selectedPlanToUpgrade,
            promoCode:
                isValidPromoCode && promoCodeRef.current?.values.promoCode !== ''
                    ? selectedPlanToUpgrade.promoCode
                    : undefined
        };

        if (isOnDemandPlan) {
            if (!isTerms) setShowTermsError(true);
            if (!isAuthorize) setShowAuthorizeError(true);

            if (!isAuthorizeValid() || !isTermsValid()) return;
        }

        dispatch(
            membershipUpgradePlanRoutine.trigger({
                payload: payload,
                onSuccess: () => {
                    navigate('/secure/profile/membership/upgrade/success');
                },
                onFailure: (error: unknown) => {
                    const errorMessage: string =
                        isObject(error) && 'messageText' in error ? String(error.messageText) : '';
                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            onClose: () => dispatch(closeModal({})),
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('components.membershipModals.errorTitle')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <MembershipSettingsErrorModal
                                    variation={
                                        errorMessage?.includes(MEMBERSHIP_RESPONSE_ERRORS.PAYMENT_NOT_AUTHORIZED)
                                            ? 'paymentError'
                                            : 'genericError'
                                    }
                                    memberName={''}
                                    t={t}
                                    onClose={() => dispatch(closeModal({}))}
                                />
                            ),
                            ctas: []
                        })
                    );
                }
            })
        );
    };

    /**
     * If the user didn't have plans to upgrade, they would be redirected to the membership settings page.
     */
    useEffect(() => {
        if (membershipDetails && !isPlanUpgradable) {
            navigate('/secure/profile/membership');
        }
    }, [membershipDetails, isPlanUpgradable]);

    // Call the plans info in page.
    useEffect(() => {
        // DRX-2280: https://mincainc.atlassian.net/browse/DRX-2280
        // TODO: This routine is currently being invoked from the Membership registration flow.
        // As a temporary measure, we are adjusting this object to meet the requirements of this page.
        // However, it is necessary to update the API response to retrieve only the available plans for each patient.
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());

        if (profileObject && membershipDetails) {
            // For on demand users, set default tab as "Annually"
            dispatch(
                membershipActions.setPayMentModel(
                    isOnDemandPlan ? defaultTabOption : membershipDetails?.paymentFrequency
                )
            );

            // Get upgradable plans
            dispatch(
                membershipGetUpgradablePlansRoutine.trigger({
                    data: { epostPatientNum: profileObject?.epostPatientNum }
                })
            );
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipID, membershipDetails, profileObject, dispatch]);

    const plans = useMemo(() => {
        return generateAvailablePlans(
            paymentModel,
            planDetails,
            isOnDemandPlan,
            isCarePlan,
            membershipDetails,
            selectedPlanId,
            handlePlanSelection,
            pricingDetails
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planDetails, paymentModel, membershipDetails, selectedPlanId, handlePlanSelection, pricingDetails]);

    useEffect(() => {
        // Setting plans when information has been load to
        // reduce # of executed processes
        if (!isProfileLoading || !isLoadingDetails) {
            setAvailablePlans(plans as MembershipAvailablePlanCardProps[]);
        }
    }, [plans, isProfileLoading, isLoadingDetails]);

    useEffect(() => {
        if (isCarePlan) {
            handlePlanSelection({
                membershipPlanId: pricingDetails[0]?.PlanId,
                chargeAmount: pricingDetails[0]?.ChargeAmount,
                newValuePlan: pricingDetails[0]?.RecurringBilling,
                termlength: pricingDetails[0]?.TypeOfRecurrence
            });
        }
        dispatch(
            membershipActions.setSelectedPlan({
                ...selectedPlan,
                cardSeqNum: selectedPaymentMethod ? selectedPaymentMethod?.cardSeqNum : defaultCreditCard?.cardSeqNum
            })
        );
    }, [
        defaultCreditCard?.cardSeqNum,
        dispatch,
        handlePlanSelection,
        isCarePlan,
        pricingDetails,
        selectedPlan,
        selectedPaymentMethod
    ]);

    // Validated the API response to show or not a related error.
    useEffect(() => {
        if (isValidPromoCode !== undefined) {
            const promoCodeError = isValidPromoCode ? 'valid' : 'invalid';
            promoCodeRef.current?.setErrors({
                promoCode: promoCodeError
            });
            promoCodeRef.current?.setSubmitting(false);
        }
    }, [isValidPromoCode]);

    if (!ENABLE_MEMBERSHIP) {
        navigate('/secure/profile/family-account');
        return null;
    }

    return (
        <>
            <WorkflowLayout
                backgroundImage={whiteFeathersBackground}
                metaData={{ nodeTitle: t('pages.membershipUpgradeYourPlan.metadata') }}
                suppressApplicationPage={false}
                showMainMenu
            >
                <PageSection className="page-layout">
                    {isProfileLoading || isLoadingDetails ? (
                        <Spinner t={t} isVisible={isLoadingDetails} />
                    ) : (
                        <>
                            <div className="membership-upgrade-your-plan-page">
                                <InternalHeader
                                    title={
                                        isOnDemandPlan
                                            ? t('pages.membershipUpgradeYourPlan.pageSections.onDemandCopy')
                                            : t('pages.membershipUpgradeYourPlan.header.title')
                                    }
                                    eyebrowText={t('pages.membershipUpgradeYourPlan.header.eyebrowText')}
                                    body={
                                        <>
                                            {isOnDemandPlan && (
                                                <p className="upgrade-plan-body">
                                                    {t('pages.membershipUpgradeYourPlan.header.bodyText')}
                                                </p>
                                            )}
                                            <Link
                                                to="/birdi-care/explore-plans"
                                                label={t('pages.membershipUpgradeYourPlan.header.urlText')}
                                                dataGALocation="Membership Upgrade Your Plan"
                                            />
                                        </>
                                    }
                                    sectionIndex={0}
                                    columnWidthOnDesktop={12}
                                    variant="blue-background"
                                    showSpacer
                                />

                                <div className="membership-upgrade-your-plan-page__content-container">
                                    <div className="membership-upgrade-your-plan-page__content-container__available-plans-container">
                                        <PageSectionTitle
                                            title={
                                                isOnDemandPlan
                                                    ? t(
                                                          'pages.membershipUpgradeYourPlan.pageSections.onDemandChoosePlans'
                                                      )
                                                    : t('pages.membershipUpgradeYourPlan.pageSections.availablePlans')
                                            }
                                        />
                                        {isOnDemandPlan && (
                                            <TabList
                                                tabs={['Monthly', 'Annually']}
                                                activeTab={defaultTabOption}
                                                onChangeTab={(tab) => handleTabChange(tab)}
                                            />
                                        )}
                                        <MembershipAvailablePlanCardList planList={availablePlans} />

                                        {!selectedPlanId && (
                                            <Button
                                                onClick={() => navigate('/secure/profile/membership')}
                                                className="link btn-bold"
                                                variant="link"
                                                type="button"
                                                label={t('pages.membershipUpgradeYourPlan.actionButtons.back')}
                                            />
                                        )}
                                    </div>
                                    {selectedPlanId && (
                                        <>
                                            <div className="membership-upgrade-your-plan-page__content-container__payments-container">
                                                <PageSectionTitle
                                                    title={t(
                                                        'pages.membershipUpgradeYourPlan.pageSections.paymentInfo'
                                                    )}
                                                />

                                                {showLoading ? (
                                                    <SpinnerInline />
                                                ) : (
                                                    <PaymentMethods
                                                        showLabel
                                                        withNewPaymentButton
                                                        showSelectCardRadioInput
                                                        paymentData={allPaymentData}
                                                        isProfile={false}
                                                        onCardSelectionChange={handlePaymentMethodSelection}
                                                        selectedCardSeqNum={
                                                            selectedPaymentMethod?.cardSeqNum ||
                                                            defaultCreditCard?.cardSeqNum
                                                        }
                                                        hasMembershipFilters={isOnDemandPlan ? false : true}
                                                    />
                                                )}
                                            </div>
                                            <div className="membership-upgrade-your-plan-page__content-container__cart-total">
                                                <MembershipPaymentFormPromoCode
                                                    onSubmit={submitPromCode}
                                                    ref={promoCodeRef}
                                                />
                                                <MembershipCartTotals
                                                    t={t}
                                                    feeTotal={selectedPlan ? selectedPlan?.JoinFee : 0}
                                                    membershipTotal={
                                                        isCarePlan ? careUserTotalPrice : onDemandUserTotalPrice
                                                    }
                                                    hasInfoAlert={hasAdjustment}
                                                    isCarePlan={isCarePlan}
                                                    isOnDemandPlan={isOnDemandPlan}
                                                    isRecurrentPayment={true}
                                                    defaultFee={DEFAULT_MEMBERSHIP_JOIN_FEE}
                                                    initialChargeAmount={InitialChargeAmount}
                                                    appliedDiscount={
                                                        isCarePlan
                                                            ? careUserAppliedDiscount
                                                            : onDemandUserAppliedDiscount
                                                    }
                                                    handleRemoveDiscount={handleRemoveDiscount}
                                                    promoCode={promoCode}
                                                    isPromoCodeValid={isValidPromoCode}
                                                    isSubmitting={promoCodeRef}
                                                    paymentModel={paymentModel}
                                                />

                                                {isOnDemandPlan && (
                                                    <div className="checkbox-content">
                                                        <div className="checkbox-grid">
                                                            <Checkbox
                                                                label={
                                                                    <div className="">
                                                                        {t('membership.selectPlan.terms')}
                                                                        {
                                                                            // eslint-disable-next-line
                                                                            <a
                                                                                className="text-underline"
                                                                                href="#"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleTermsModal();
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    'membership.selectPlan.birdiCareMembershipTerms'
                                                                                )}
                                                                            </a>
                                                                        }
                                                                        .
                                                                    </div>
                                                                }
                                                                id="terms"
                                                                name="terms"
                                                                onClick={(isChecked: boolean) =>
                                                                    handleCheckTerms(isChecked)
                                                                }
                                                                onCheckChanged={(isChecked: boolean) =>
                                                                    handleCheckTerms(isChecked)
                                                                }
                                                                hasError={!!showTermsError}
                                                            />
                                                            {showTermsError && (
                                                                <p className="checkbox-label-error">
                                                                    {t('membership.selectPlan.termsError')}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="checkbox-grid">
                                                            <Checkbox
                                                                label={
                                                                    <div
                                                                        className="checkbox-grid-label"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: sanitizeHtml(
                                                                                t('membership.selectPlan.authorize', {
                                                                                    phoneNumber: getPhoneNumber({
                                                                                        isEnd: true
                                                                                    })
                                                                                })
                                                                            )
                                                                        }}
                                                                    />
                                                                }
                                                                id="authorize"
                                                                name="authorize"
                                                                onClick={(isChecked: boolean) =>
                                                                    handleCheckAuthorize(isChecked)
                                                                }
                                                                onCheckChanged={(isChecked: boolean) =>
                                                                    handleCheckAuthorize(isChecked)
                                                                }
                                                                hasError={!!showAuthorizeError}
                                                            />
                                                            {showAuthorizeError && (
                                                                <p className="checkbox-label-error">
                                                                    {t('membership.selectPlan.authorizeError')}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="membership-upgrade-your-plan-page__content-container__action-buttons">
                                                <Button
                                                    async
                                                    type="button"
                                                    onClick={handleUpgradePlan}
                                                    label={t(
                                                        'pages.membershipUpgradeYourPlan.actionButtons.upgradePlan'
                                                    )}
                                                    className="sm-full"
                                                    isBusy={isLoadingUpgradePlan}
                                                    disabled={isLoadingUpgradePlan}
                                                />
                                                <Button
                                                    onClick={() => navigate('/secure/profile/membership')}
                                                    className="link"
                                                    variant="link"
                                                    type="button"
                                                    label={t('pages.membershipUpgradeYourPlan.actionButtons.back')}
                                                    disabled={isLoadingUpgradePlan}
                                                />
                                            </div>

                                            {isCarePlan && (
                                                <div className="membership-upgrade-your-plan-page__content-container__disclaimer">
                                                    <p>
                                                        {t('pages.membershipUpgradeYourPlan.advice', {
                                                            todayValue: formatPrice(pricingDetails[0]?.ChargeAmount),
                                                            nextValue: formatPrice(pricingDetails[0]?.RecurringBilling)
                                                        }).replace('Upgrade Plan', `"${'Upgrade Plan'}"`)}
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </PageSection>
            </WorkflowLayout>

            <Footer
                copyrightYear={new Date().getFullYear().toString()}
                aboutUsPaths={data.allMenuLinkContentFooter.nodes as NavPath[]}
                socialMediaPaths={data.allMenuLinkContentFooterSocial.nodes as NavPath[]}
                howCanWeHelpPaths={data.allMenuLinkContentFooterSupport.nodes as NavPath[]}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes as NavPath[]}
                solutionsPaths={data.allMenuLinkContentFooterCompany.nodes as NavPath[]}
                getAppPaths={data.allMenuLinkContentFooterGetTheApp.nodes as BadgePath[]}
                contactPaths={data.allMenuLinkContentFooterContact.nodes as NavPath[]}
                groupsPaths={data.allMenuLinkContentFooterGroupsBusiness.nodes as NavPath[]}
                physicalAddress={data.allBlockContentAddresses.nodes[0]?.field_physical_address}
                mailingAddress={data.allBlockContentAddresses.nodes[0]?.field_mailing_address}
                specialtyMailingAddress={data.allBlockContentAddresses.nodes[0]?.field_specialty_mailing_address}
                specialtyPhysicalAddress={data.allBlockContentAddresses.nodes[0]?.field_specialty_physical_address}
                specialtiesPaths={data.allMenuLinkContentFooterSpecialtyByBirdi.nodes as NavPath[]}
            />
        </>
    );
};

export default MembershipUpgradePlanPage;

export const query = graphql`
    query MembershipUpgradePlanPageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        whiteFeathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentAlertBanner(
            sort: { fields: changed, order: DESC }
            filter: { field_alert_active: { eq: true } }
        ) {
            nodes {
                field_alert_text
                field_alert_priority_level
                field_alert_permanent
                field_alert_link {
                    title
                    uri: url
                }
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
        allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                isButton: field_menu_cta
                link {
                    url
                }
                label: title
                requiresAccountAuth: field_requires_account_auth
                requiresMembership: field_requires_membership
                isMobile: field_menu_only_mobile
                buttonVariant: field_menu_button_variant
                langcode
            }
        }
        allMenuLinkContentFooter(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterSocial(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterSupport(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                field_menu_body {
                    processed
                }
                langcode
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterCompany(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterGetTheApp(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                badgeType: field_badge_type
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterContact(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                field_menu_body {
                    processed
                }
                langcode
            }
        }
        allMenuLinkContentFooterGroupsBusiness(
            sort: { order: ASC, fields: weight }
            filter: { enabled: { eq: true } }
        ) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterSpecialtyByBirdi(
            sort: { order: ASC, fields: weight }
            filter: { enabled: { eq: true } }
        ) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allNodeApplicationPage {
            nodes {
                title
                id
                drupal_internal__nid
                relationships {
                    field_application_page_content {
                        ...supportedParagraphs
                    }
                }
                path {
                    langcode
                    alias
                }
                field_ap_show_on_subpages
                field_meta_tags {
                    title
                    description
                    abstract
                    keywords
                }
            }
        }
    }
`;
